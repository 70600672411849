
  export default {
    head: {
      title: 'MPower Promo Inc.',
      meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: 'https://files.mpowerpromo.com/assets/favicons/favicon.ico' },
        { rel: 'stylesheet', href: 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css' },
      ],
    },
  };
